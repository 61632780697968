import Container from '../container/container'
import { useTranslation } from 'react-i18next';
import contactStyles from './contact.module.scss';
import { Mail, PhoneCall, Mailbox, MapPin, Phone, Printer } from "lucide-react";

// const contacts = [
//     {
//         name: 'Eng. Ahmed Shawky',
//         position: 'Executive Director',
//         number: 966565480010,
//         email: 'Ash@arabtech-est.com',
//         gridArea: 'Shawky'
//     },
//     {
//         name: 'Mr. Zahid Gulzar',
//         position: 'Sales And Procurement',
//         number: 966565480010,
//         email: 'Ash@arabtech-est.com',
//         gridArea: 'Zahid'
//     },
//     {
//         name: 'Eng. Hamid',
//         position: `Engineering Consulting Division &
//                     Academic/On-Site Training Division`,
//         number: 966565480010,
//         email: 'Ash@arabtech-est.com',
//         gridArea: 'Hamid'
//     },
//     {
//         name: 'Eng. Mustafa',
//         position: `Projects Division &
//                     Field Services Division`,
//         number: 966565480010,
//         email: 'Ash@arabtech-est.com',
//         gridArea: 'Mustafa'
//     },
//     {
//         name: 'Eng. Rami',
//         position: 'For unmentioned Services Contact to',
//         number: 966565480010,
//         email: 'rami@arabtech-est.com',
//         gridArea: 'Rami'
//     },

// ]

export default function Contact() {
    const { t, i18n } = useTranslation();

    return (
        <Container>

            <div className={`${contactStyles.contactPage} ${i18n.language === 'ar' && contactStyles.contactPage_ar}`}>

                <h1 className="title">{t('contact')}</h1>
                <div className={contactStyles.contactUsWrapper}>

                    {i18n.language === 'en' ?
                        <MembersList />
                        :
                        <MembersList_AR />
                    }
                </div>

                <div className={contactStyles.findUsWrapper}>
                    <h1 className="title">{t('findUs')}</h1>
                    <div className={`${contactStyles.loacteInfo} ${contactStyles.cardDesign}`}>
                        {i18n.language === 'en' ?
                            <ul>
                                <li>
                                    <MapPin size={19} className={contactStyles.icon} />
                                    Arab Technical Company, Office no. 20, <br />
                                    Babader plaza, next to Jeddah General Court, <br />
                                    King Fahd Street, Al-Azzeziah, Jeddah, <br />Kingdom of Saudi Arabia.
                                </li>
                                <li>
                                    <Mailbox size={20} className={contactStyles.icon} />
                                    P.O. Box: 36225.
                                    Postal Code: Jeddah, 21419.
                                </li>
                            </ul>
                            :
                            <ul>
                                <li>
                                    <MapPin size={19} className={contactStyles.icon} /> 
                                    شركة عرب التقنية المحدودة، مكتب رقم 20، <br />
                                    بابدر بلازا، بجوار المحكمة العامة بجدة، <br />
                                    شارع الملك فهد، العزيزية، جدة، <br />
                                    المملكة العربية السعودية.
                                </li>
                                <li>
                                    <Mailbox size={20} className={contactStyles.icon} /> 
                                    صندوق بريد: 36225. الرمز البريدي: جدة، 21419.
                                </li>
                            </ul>
                        }

                        <div className={contactStyles.map}>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2216.7908177324452!2d39.18848161834931!3d21.551638215784845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3d0315b03460d%3A0xcd0b7623ceea73f!2zQXJhYiBUZWNoIENvLiBMdGQgLSDYtNix2YPYqSDYudix2Kgg2KfZhNiq2YLZhtmK2Ycg2KfZhNmF2K3Yr9mI2K_Zhw!5e0!3m2!1sen!2ssa!4v1702856226006!5m2!1sen!2ssa" allowfullscreen="" loading="lazy" title="ArabTech Co. Office location" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>

                    </div>
                </div>
            </div>
        </Container>
    )
}

function MembersList() {
    return (
        <ul className={`${contactStyles.membersList}`}>

            {/* <ContactDetails contacts={contacts} /> */}

            <li className={contactStyles.cardDesign} style={{ gridArea: 'Shawky' }}>
                <h4>Executive Director</h4>
                <h2>Eng. Ahmed Shawky</h2>
                <p>
                    <a href="tel:+966565480010"><PhoneCall size={16} className={contactStyles.icon} /> +966 56 548 0010</a>
                    <a href="mailto:ash@arabtech-est.com"><Mail size={16} className={contactStyles.icon} /> Ash@arabtech-est.com</a>
                </p>
            </li>

            <li className={contactStyles.cardDesign} style={{ gridArea: 'Zahid' }}>
                <h4>Sales And Procurement</h4>
                <h2>Eng. Zahid Gulzar</h2>
                <p>
                    <a href="tel:+966542768957"><PhoneCall size={16} className={contactStyles.icon} /> +966 54 276 8957</a>
                    <a href="mailto:zahid.gulzar@arabtech-est.com"><Mail size={16} className={contactStyles.icon} /> Zahid.gulzar@arabtech-est.com</a>
                </p>
            </li>

            <li className={contactStyles.cardDesign} style={{ gridArea: 'Hamid' }}>
                <h4>Engineering Consulting Division <br />
                    Academic/On-Site Training Division</h4>
                <h2>Eng. Hamid Gulzar</h2>
                <p>
                    <a href="tel:+966593175330"><PhoneCall size={16} className={contactStyles.icon} /> +966 59 317 5330</a>
                    <a href="mailto:hamid.ali@arabtech-est.com"><Mail size={16} className={contactStyles.icon} /> Hamid.ali@arabtech-est.com</a>
                </p>
            </li>

            <li className={contactStyles.cardDesign} style={{ gridArea: 'Mustafa' }}>
                <h4>Projects Division <br />
                    Field Services Division</h4>
                <h2>Eng. Mustafa Hussein</h2>
                <p>
                    <a href="tel:+966500093378"><PhoneCall size={16} className={contactStyles.icon} /> +966 50 009 3378</a>
                    <a href="mailto:mustafa.hussein@arabtech-est.com"><Mail size={16} className={contactStyles.icon} /> Mustafa.hussein@arabtech-est.com</a>
                </p>
            </li>

            <li className={contactStyles.cardDesign} style={{ gridArea: 'Rami' }}>
                <h4>For unmentioned Services Contact to</h4>
                <h2>Eng. Rami AlGhamdi</h2>
                <p>
                    <a href="tel:+966555112406"><PhoneCall size={16} className={contactStyles.icon} /> +966 55 511 2406</a>

                    <a href="mailto:rami.alghamdi@arabtech-est.com"><Mail size={16} className={contactStyles.icon} /> Rami.alghamdi@arabtech-est.com</a>

                    <a href="mailto:info@arabtech-est.com"><Mail size={16} className={contactStyles.icon} /> Info@arabtech-est.com</a>

                    <a href="tel:+966126350704"><Phone size={19} className={contactStyles.icon} /> / <Printer size={19} className={contactStyles.icon} /> +966126350704.</a>
                </p>
            </li>

        </ul>
    );
}

function MembersList_AR() {
    return (
        <ul className={`${contactStyles.membersList}`}>

            {/* <ContactDetails contacts={contacts} /> */}

            <li className={contactStyles.cardDesign} style={{ gridArea: 'Shawky' }}>
                <h4>المدير التنفيذي</h4>
                <h2>م. أحمد شوقي</h2>
                <p>
                    <a href="tel:+966565480010"><PhoneCall size={16} className={contactStyles.icon} /> +966 56 548 0010</a>
                    <a href="mailto:ash@arabtech-est.com"><Mail size={16} className={contactStyles.icon} /> Ash@arabtech-est.com</a>
                </p>
            </li>

            <li className={contactStyles.cardDesign} style={{ gridArea: 'Zahid' }}>
                <h4>المبيعات والمشتريات</h4>
                <h2>م. زاهد جولزار</h2>
                <p>
                    <a href="tel:+966542768957"><PhoneCall size={16} className={contactStyles.icon} /> +966 54 276 8957</a>
                    <a href="mailto:zahid.gulzar@arabtech-est.com"><Mail size={16} className={contactStyles.icon} /> Zahid.gulzar@arabtech-est.com</a>
                </p>
            </li>

            <li className={contactStyles.cardDesign} style={{ gridArea: 'Hamid' }}>
                <h4>قسم الاستشارات الهندسية <br />
                    قسم التدريب الأكاديمي/في الموقع</h4>
                <h2>م. حميد جولزار</h2>
                <p>
                    <a href="tel:+966593175330"><PhoneCall size={16} className={contactStyles.icon} /> +966 59 317 5330</a>
                    <a href="mailto:hamid.ali@arabtech-est.com"><Mail size={16} className={contactStyles.icon} /> Hamid.ali@arabtech-est.com</a>
                </p>
            </li>

            <li className={contactStyles.cardDesign} style={{ gridArea: 'Mustafa' }}>
                <h4>قسم المشاريع <br />
                    قسم الخدمات الميدانية</h4>
                <h2>م. مصطفى حسين</h2>
                <p>
                    <a href="tel:+966500093378"><PhoneCall size={16} className={contactStyles.icon} /> +966 50 009 3378</a>
                    <a href="mailto:mustafa.hussein@arabtech-est.com"><Mail size={16} className={contactStyles.icon} /> Mustafa.hussein@arabtech-est.com</a>
                </p>
            </li>

            <li className={contactStyles.cardDesign} style={{ gridArea: 'Rami' }}>
                <h4>للإستفسارات </h4>
                <h2>م. رامي الغامدي</h2>
                <p>
                    <a href="tel:+966555112406"><PhoneCall size={16} className={contactStyles.icon} /> +966 55 511 2406</a>

                    <a href="mailto:rami.alghamdi@arabtech-est.com"><Mail size={16} className={contactStyles.icon} /> Rami.alghamdi@arabtech-est.com</a>

                    <a href="mailto:info@arabtech-est.com"><Mail size={16} className={contactStyles.icon} /> Info@arabtech-est.com</a>

                    <a href="tel:+966126350704"><Phone size={19} className={contactStyles.icon} /> / <Printer size={19} className={contactStyles.icon} /> +966126350704.</a>
                </p>
            </li>

        </ul>
    );
}