import React from 'react'
import { Link } from 'react-router-dom';
import Container from '../../../container/container'
import { useTranslation } from 'react-i18next';
import serviceStyles from './services.module.scss'


export default function Services() {
  const { t, i18n } = useTranslation();

  return (
    <Container>

      <div className={serviceStyles.services}>
        <h1 className='title'>{t('services')}</h1>

        <div className={`${serviceStyles.services_list} ${i18n.language === 'ar' && serviceStyles.services_list_ar}`}>

          <div className={serviceStyles.services_list_num}>
            <h2>{t('headin_list_1')}</h2>
            <p>{t('prag_list_1')}</p>
          </div>

          <div className={serviceStyles.services_list_num}>
            <h2>{t('headin_list_2')}</h2>
            <p>{t('prag_list_2')}</p>
          </div>

          <div className={serviceStyles.services_list_num}>
            <h2>{t('headin_list_3')}</h2>
            <p>{t('prag_list_3')}</p>
          </div>

          <div className={serviceStyles.services_list_num}>
            <h2>{t('headin_list_4')}</h2>
            <p>{t('prag_list_4')}</p>
          </div>

          <div className={serviceStyles.services_list_num}>
            <h2>{t('headin_list_5')}</h2>
            <p>{t('prag_list_5')} 
              <br /><Link to="/training">More &rarr;</Link></p>
          </div>

          <div className={serviceStyles.services_list_num}>
            <h2>{t('headin_list_6')}</h2>
            <p>{t('prag_list_6')}</p>
          </div>

          <div className={serviceStyles.services_list_num}>
            <h2>{t('headin_list_7')}</h2>
            <p>{t('prag_list_7')}</p>
          </div>

        </div>
      </div>
    </Container>
  )
}

