import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import sbStyles from './sidebar.module.scss';

// const sidebarlist = [
//     {
//         title: 'services',
//         link: '/test'
//     }
// ]

export default function Sidebar() {
    const { t, i18n } = useTranslation();
    
    return (
        <div className={sbStyles.sidebar}>

            <ul className={sbStyles.sidebarList}>
                <li className={sbStyles.row} 
                id="active">
                    <Link to='/services' className={`${sbStyles.link} ${i18n.language === 'ar' ? sbStyles.link_ar : '' }`}>{t('services')}</Link>
                </li>
                <li className={sbStyles.row} 
                id="active">
                    <Link to='/projects' className={`${sbStyles.link} ${i18n.language === 'ar' ? sbStyles.link_ar : '' }`}>{t('projects')}</Link>
                </li>
                <li className={sbStyles.row} 
                id="active">
                    <Link to='/training' className={`${sbStyles.link} ${i18n.language === 'ar' ? sbStyles.link_ar : '' }`}>{t('training')}</Link>
                </li>
                <li className={sbStyles.row} 
                id="active">
                    <Link to='/gallery' className={`${sbStyles.link} ${i18n.language === 'ar' ? sbStyles.link_ar : '' }`}>{t('gallery')}</Link>
                </li>
                <li className={sbStyles.row} 
                id="active">
                    <Link to='/team' className={`${sbStyles.link} ${i18n.language === 'ar' ? sbStyles.link_ar : '' }`}>{t('team')}</Link>
                </li>
            </ul>

        </div>
    )
}