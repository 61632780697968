import React from 'react'
import Container from '../../container/container'
import { useTranslation } from 'react-i18next';
import galleryStyles from './gallery.module.scss'

const galleryList = [
    {
        URL: 'pic1.jpeg',
        Alt: 'Project image',
        Brief: 'Project view',
    },
    {
        URL: 'pic2.jpeg',
        Alt: 'Project image',
        Brief: 'Project view',
    },
    {
        URL: 'pic3.jpeg',
        Alt: 'Project image',
        Brief: 'Project view',
    },
    {
        URL: 'pic4.jpeg',
        Alt: 'Project image',
        Brief: 'Project view',
    },
    {
        URL: 'pic5.jpeg',
        Alt: 'Project image',
        Brief: 'Project view',
    },
    {
        URL: 'pic6.jpeg',
        Alt: 'Project image',
        Brief: 'Project view',
    },
    {
        URL: 'pic7.jpeg',
        Alt: 'Project image',
        Brief: 'Project view',
    },
    {
        URL: 'pic8.jpeg',
        Alt: 'Project image',
        Brief: 'Project view',
    },
    {
        URL: 'pic9.jpeg',
        Alt: 'Project image',
        Brief: 'Project view',
    },
    {
        URL: 'pic10.jpeg',
        Alt: 'Project image',
        Brief: 'Project view',
    },
    // {
    //     URL: '11.jpeg',
    //     Alt: 'Project image',
    //     Brief: 'Project view',
    // },
    // {
    //     URL: '12.jpeg',
    //     Alt: 'Project image',
    //     Brief: 'Project view',
    // },
    // {
    //     URL: '13.jpeg',
    //     Alt: 'Project image',
    //     Brief: 'Project view',
    // },
    // {
    //     URL: '14.jpeg',
    //     Alt: 'Project image',
    //     Brief: 'Project view',
    // },
    // {
    //     URL: '15.jpeg',
    //     Alt: 'Project image',
    //     Brief: 'Project view',
    // },
]

export default function Gallery() {
    const { t, i18n } = useTranslation();

    return (
        <Container>
            <h1 className='title'>{t('projectTitle')}</h1>

            <div class={`${galleryStyles.gallery} ${i18n.language === 'ar' && galleryStyles.gallery_ar}`}>
                {galleryList.map(g =>
                    <Photo alt={g.Alt} url={g.URL} brief={g.Brief} />
                )}
            </div>

        </Container>

    )
}

function Photo({ alt, brief, url }) {
    return (
        <div class={galleryStyles.gallery_item}>
            {/* style={{ gridArea: `${alt}` }} */}
            <img src={`../images/gallery/${url}`} alt={alt} className={galleryStyles.gallery_item_img} />
            {/* <p class={galleryStyles.overlay}>{brief} Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
        </div>
    )

}
