import { Route, Routes } from 'react-router-dom';
// import Sidebar from './components/sidebar/sidebar'
import Navbar from './components/navbar/navbar'
import Home from './components/home/home'
import About from './components/about/about'
import Contact from "./components/contact/contact";
import Documents from './components/documents/documents'
import Projects from './components/sidebar/experiences/projects/projects'
import Services from './components/sidebar/experiences/services/services'
import Training from './components/sidebar/experiences/training/training'
import Gallery from './components/sidebar/gallery/gallery'
import Team from './components/sidebar/team/team'
import Careers from './components/footer/careers/careers'
import Footer from './components/footer/footer'


function App() {
  return (
    <div className="app">
      <Navbar />


      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="/services" element={<Services />} />
        <Route path="/projects" element={<Projects />} />
        <Route path='/training' element={<Training />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/team" element={<Team />} />
        <Route path="/careers" element={<Careers />} />

      </Routes>

      <Footer />

    </div>
  );
}

export default App;
