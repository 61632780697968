import React from 'react';
import Container from '../../../container/container'
import { useTranslation } from 'react-i18next';
import { Component } from "lucide-react";
import trainStyles from './training.module.scss';

export default function Training() {
  const { t, i18n } = useTranslation();

  return (
    <Container>

      <div className={`${trainStyles.training} ${i18n.language === 'ar' && trainStyles.training_ar}`}>
        <h1 className='title'>{t('training')}</h1>
        <p>{t('trainingPrag')}</p>

        <div className={trainStyles.practical}>
          <h2><Component />&nbsp;&nbsp; {t('practicalـtraining')}</h2>
          <div className={trainStyles.list}>

            <div className={trainStyles.num}>
              <img src="../images/about/Picture1.svg" alt="" />
              <p>{t('practical_list_1')}</p>
            </div>

            {/* 
              <div className={trainStyles.num}>
              <img src="../images/about/Picture1.svg" alt="" />
                <p>LV & MV Switch Gear</p>
              </div>
              */}
            <div className={trainStyles.num}>
              <img src="../images/about/Picture1.svg" alt="" />
              <p>{t('practical_list_2')}</p>
            </div>
            <div className={trainStyles.num}>
              <img src="../images/about/Picture1.svg" alt="" />
              <p>{t('practical_list_3')}</p>
            </div>
            <div className={trainStyles.num}>
              <img src="../images/about/Picture1.svg" alt="" />
              <p>{t('practical_list_4')}</p>
            </div>
            <div className={trainStyles.num}>
              <img src="../images/about/Picture1.svg" alt="" />
              <p>{t('practical_list_5')}</p>
            </div>
            <div className={trainStyles.num}>
              <img src="../images/about/Picture1.svg" alt="" />
              <p>{t('practical_list_6')}</p>
            </div>
            <div className={trainStyles.num}>
              <img src="../images/about/Picture1.svg" alt="" />
              <p>{t('practical_list_7')}</p>
            </div>

          </div>
        </div>


        <div className={trainStyles.academic}>
          <h2><Component />&nbsp;&nbsp; {t('academicـtraining')}</h2>
          <div className={trainStyles.table}>
            <div className={trainStyles.table_row}>
              <h3>{t("academic_list.main_li_1")}</h3>
              <li>{t("academic_list.sub_li_1.li_1")}</li>
              <li>{t("academic_list.sub_li_1.li_2")}</li>
              <li>{t("academic_list.sub_li_1.li_3")}</li>
              <li>{t("academic_list.sub_li_1.li_4")}</li>
            </div>
            <div className={trainStyles.table_row}>
              <h3>{t("academic_list.main_li_2")}</h3>
              <li>{t("academic_list.sub_li_2.li_1")}</li>
              <li>{t("academic_list.sub_li_2.li_2")}</li>
              <li>{t("academic_list.sub_li_2.li_3")}</li>
              <li>{t("academic_list.sub_li_2.li_4")}</li>
            </div>
            <div className={trainStyles.table_row}>
              <h3>{t("academic_list.main_li_3")}</h3>
              <li>{t("academic_list.sub_li_3.li_1")}</li>
              <li>{t("academic_list.sub_li_3.li_2")}</li>
              <li>{t("academic_list.sub_li_3.li_3")}</li>
              <li>{t("academic_list.sub_li_3.li_4")}</li>
            </div>
            <div className={trainStyles.table_row}>
              <h3>{t("academic_list.main_li_4")}</h3>
              <li>{t("academic_list.sub_li_4.li_1")}</li>
              <li>{t("academic_list.sub_li_4.li_2")}</li>
              <li>{t("academic_list.sub_li_4.li_3")}</li>
              <li>{t("academic_list.sub_li_4.li_4")}</li>
            </div>
          </div>

        </div>
      </div>

    </Container>
  )
}

