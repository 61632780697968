import React from 'react'
import Container from '../../container/container'
// import { useTranslation } from 'react-i18next';
import careerStyles from './careers.module.scss'



export default function careers() {
    // const { t, i18n } = useTranslation();
    return (
        <Container>

            <div className={careerStyles.careers}>
                <div className={careerStyles.TypingAnimate}>
                    {/* <img src="../images/careers/joinUs.png" alt="" /> */}

                    Join Our Team!
                </div>
                <div className={careerStyles.careers_txt}>
                    <p>
                        At <b>ArabTech</b>, we take pride in our ability to attract and retain exceptional talent in the industry. Our success is built upon the foundation of our people. We offer a stable work environment, competitive salaries, comprehensive benefits, and foster a culture that values diversity and promotes teamwork. Join our team and experience the benefits of being part of a company that truly values its employees.
                        <br /><br />
                    </p>

                    {/* <p>
                        To apply, please send your CV to: &nbsp;
                        <a href="mailto:careers@arabtech-est.com">
                            careers@arabtech-est.com
                        </a>
                    </p> */}

                    <p>
                        To apply, please Check our <a href="https://www.linkedin.com/company/arabtech-est/mycompany/" target='_blank'>LinkedIn</a> company account.
                    </p>
                    <p>
                        Or send your CV to: &nbsp;
                        <a href="mailto:careers@arabtech-est.com">careers@arabtech-est.com</a>
                    </p>

                </div>
            </div>
        </Container>
    )
}

