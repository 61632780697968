import React from 'react';
import { Link } from 'react-router-dom';
import { Linkedin, Facebook } from "lucide-react";
import footerStyles from "./footer.module.scss";



export default function Footer() {
    return (
        <div className={footerStyles.footerContainer}>

            <div className={footerStyles.darkLine}>
                <div className={footerStyles.footer}>
                    <ul>
                        <li>Babader plaza,<br />
                            King Fahd Street, Jeddah
                        </li>
                        <li>P.O. 36225. <br />
                            Jeddah 21419.
                        </li>
                        <li>
                            <a href="tel:+966126350704">
                                +966126350704
                            </a>
                            <a href="mailto:info@arabtech-est.com">Info@arabtech-est.com
                            </a>
                        </li>
                        <li>
                            <Link to="/careers">Careers</Link>
                        </li>

                        <li className={footerStyles.social}>
                            <div>
                                <a href='https://www.linkedin.com/company/arabtech-est/' target='_blank'><Linkedin size={18} /></a>
                            </div>
                            {/* <div>
                                <a href='#' target='_blank'><Facebook size={18} /></a>
                            </div> */}
                        </li>
                    </ul>
                </div>
            </div>
            <div className={footerStyles.lightLine}></div>


        </div>
    )
}
