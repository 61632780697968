import React from 'react'
import 'react-slideshow-image/dist/styles.css'
import { useTranslation } from 'react-i18next';
// import { Fade } from 'react-slideshow-image';
import heroStyles from './hero.module.scss'

// const heroImages = [
//     // {
//     //     url: 'hero1.jpg',
//     // },
//     {
//         url: 'hero2.jpg',
//     },
//     {
//         url: 'hero3.jpg',
//     },
//     // {
//     //     url: 'hero4.jpg',
//     // },
// ]

export default function Hero() {
    const { t, i18n } = useTranslation();

    return (
        <section className={heroStyles.section_hero}>

            <div className={`${heroStyles.hero_conatiner} ${i18n.language === 'ar' && heroStyles.hero_conatiner_ar}`}>
                <div className={heroStyles.col_block}>
                    <div className={heroStyles.hero}>
                        {i18n.language === 'en' ?
                            <div className={heroStyles.hero_text_box}>
                                <h1 className={heroStyles.heading_primary}>
                                    Arab Technical Company Ltd.
                                </h1>
                                <h2 className={heroStyles.heading_secondary}>
                                    ENGINEERING, CONSTRUCTION, <span>FIELD SERVICES, and O&M</span>
                                </h2>
                                <p className={heroStyles.hero_brief}>
                                    <b>ArabTech</b> was incorporated in 2004 with the purpose of providing support systems and services for Substations and Industrial sectors in Western Province of KSA.
                                </p>
                            </div>
                            :
                            <div className={heroStyles.hero_text_box}>
                                <h1 className={heroStyles.heading_primary}>
                                    شـركـة عـرب التـقـنـيـة المـحـدودة
                                </h1>
                                <h2 className={heroStyles.heading_secondary}>
                                    الهندسة والبناء والخدمات الميدانية 
                                    <span>
                                        والتشغيل والصيانة
                                    </span>
                                </h2>
                                <p className={heroStyles.hero_brief}>
                                    تأسست شركة
                                    <b> عرب تك </b>
                                    في عام 2004 بهدف توفير أنظمة الدعم والخدمات لمحطات الكهرباء والقطاعات الصناعية في المنطقة الغربية من المملكة العربية السعودية.
                                </p>
                            </div>}
                    </div>
                </div>
                <div className={heroStyles.col_block}>
                    <div class={heroStyles.composition}>

                        <img
                            sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                            alt="Photo 1"
                            className={`${heroStyles.composition__photo} ${heroStyles.composition__photo_p1}`}
                            src="../images/hero/hero_pic_1.jpeg" />

                        <img
                            sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                            alt="Photo 2"
                            className={`${heroStyles.composition__photo} ${heroStyles.composition__photo_p2}`}
                            src="../images/hero/hero_pic_2.jpeg" />

                        <img
                            sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                            alt="Photo 3"
                            className={`${heroStyles.composition__photo} ${heroStyles.composition__photo_p3}`}
                            src="../images/hero/hero_pic_3.jpeg" />
                        <img
                            sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                            alt="Photo 1"
                            className={`${heroStyles.composition__photo} ${heroStyles.composition__photo_p4}`}
                            src="../images/hero/hero_pic_4.jpeg" />

                        <img
                            sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                            alt="Photo 2"
                            className={`${heroStyles.composition__photo} ${heroStyles.composition__photo_p5}`}
                            src="../images/hero/hero_pic_6.jpeg" />

                        {/* <img
                            sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                            alt="Photo 3"
                            className={`${heroStyles.composition__photo} ${heroStyles.composition__photo_p6}`}
                            src="../images/gallery/pic6.jpeg" /> */}

                    </div>
                </div>
            </div>
        </section>
    )
}

// function HeroImagesSlider({ images }) {
//     return (
//         <div className={heroStyles.imgWrapper}>
//             <Fade>
//                 {images.map(img => (
//                     <img src={`../images/hero/${img.url}`} alt="hero images" className={heroStyles.hero_img} />
//                 ))}
//             </Fade>
//         </div>
//     )
// }