import React from 'react';
import { changeLanguage } from 'i18next';

export default function MultilingualBtn({ t, i18n }) {
    return (
        <div className={MultilingualBtn}>
            {i18n.language === 'en' && <button className='langBtn' onClick={() => changeLanguage('ar')}>AR</button>}
            {i18n.language === 'ar' && <button className='langBtn' onClick={() => changeLanguage('en')}>EN</button>}
        </div>
    );
}
