import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import Slider from "react-slick";
// import { Fade } from 'react-slideshow-image';
import Marquee from "react-fast-marquee";
import Hero from '../hero/hero';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from '../container/container'
import homeStyles from './home.module.scss';




// const newsDetails = [
//     {
//         title: 'Title1',
//         url: 'pic1.jpeg',
//         text: 'Text 1 Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, harum eaque velit moll'
//     },
//     {
//         title: 'Title2',
//         url: 'pic5.jpeg',
//         text: 'Text 2 Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, harum eaque velit moll'
//     },
//     {
//         title: 'Title3',
//         url: 'pic4.jpeg',
//         text: 'Text 3 Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, harum eaque velit mollitia molestias neque aperiam dolore voluptas praesentium doloribus!'
//     },
// ]

const clientsLogo = [
    {
        alt: "Aramco logo",
        url: "aramcoLogo.png"
    },
    {
        alt: "Sabic logo",
        url: "sabicLogo.png"
    },
    {
        alt: "SEC logo",
        url: "secLogo.png"
    },
    {
        alt: "ABB logo",
        url: "ABBLogo.png"
    },
    {
        alt: "Royal Commission at Yanbu logo",
        url: "RoyalComLogo.jpg"
    },
    {
        alt: "Samsung logo",
        url: "samsungLogo.png"
    },
    {
        alt: "Sadara logo",
        url: "sadaraLogo.png"
    },
    {
        alt: "Sasref Logo",
        url: "sasrefLogo.jpeg"
    },
    {
        alt: "Maaden Logo",
        url: "maadenLogo.png"
    },
    {
        alt: "Hitachi Logo",
        url: "hitachiLogo.png"
    },
    {
        alt: "SWCC logo",
        url: "swccLogo.png"
    },
    {
        alt: "Jedco logo",
        url: "jedcoLogo.png"
    },
]


export default function Home() {
    const { t, i18n } = useTranslation();

    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     autoplaySpeed: 5000,
    // };

    // const arr = ['test1 Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt corrupti debitis qui harum maiores. Fuga nemo tempora id ad magni.', 'test2', 'test3']

    return (
        <div className={homeStyles.home}>
            <div className={homeStyles.hero}>
                <Hero/>
            </div>
            
            <Container>

                <div className={`${homeStyles.section_home}`}>
                    <div className={`${homeStyles.about} ${i18n.language === 'ar' ? homeStyles.about_ar : ''}`}>
                        <h1 className={`h1_mrgn_btm`}>{t('homeBrief')}</h1>
                        {i18n.language === 'en' ?
                            <p className={homeStyles.about_txt}>
                                Since its creation, <b>ArabTech</b> has evolved into a multi-disciplined organization comprised of multiple divisions and affiliates. This evolution was inspired by the growing needs of customers all over the kingdom.
                            </p>
                            :
                            <p className={homeStyles.about_txt}>
                                تأسست &nbsp;
                                <b>
                                    شركة عرب التقنية المحدودة &nbsp;
                                </b>
                                في عام 2004م بهدف توفير أنظمة وخدمات الدعم لمحطات الكهرباء والقطاعات الصناعية في المنطقة الغربية من المملكة العربية السعودية.
                            </p>
                        }

                        <Link to="/about" className={`${homeStyles.about_btn} btn`}>{t('readMore')}</Link>
                    </div>


                    <div className={`${homeStyles.news} ${i18n.language === 'ar' ? homeStyles.news_ar : ''}`}>
                        <h1 className={`h1_mrgn_btm`}>{t('latestNews')}</h1>
                        <div className={homeStyles.news_container}>
                            <div className={homeStyles.news_txt_box}>
                                <p className={`${homeStyles.news_description} p_mrgn_btm`}>
                                    {t('news_description')}
                                </p>
                            </div>
                            <div className={homeStyles.news_img_box}>
                                <img src="../images/home/pic1.png" alt="" className={homeStyles.news_img} />
                            </div>
                        </div>
                    </div>

                    <div className={`${homeStyles.client_logo} ${i18n.language === 'ar' ? homeStyles.client_logo_ar : ''}`}>
                        <h1 className={`h1_mrgn_btm`}>{t('ourClients')}</h1>
                        <div className={homeStyles.slider}>
                            <LogoContainer logo={clientsLogo} extension="clients" dir='left' />
                        </div>
                    </div>
                </div>

            </Container >
        </div>
    )
}


// function NewsDetails({ url, title, text }) {
//     return (
//         <div className={homeStyles.news}>
//             <div className={homeStyles.newsPic}>
//                 <img src={`../images/gallery/${url}`} alt="News images" />
//             </div>
//             <div className={homeStyles.newsText}>
//                 <h2>{title}</h2>
//                 <p>{text}</p>
//             </div>
//         </div>
//     )
// }


function LogoContainer({ logo, extension, dir }) {
    return (
        <Marquee pauseOnHover speed={30} direction={dir}>
            {logo.map(l => (
                <div className={homeStyles.logo}>
                    <img src={`../images/logo/${extension}/${l.url}`} alt={l.alt} loading="lazy" />
                </div>
            ))}
        </Marquee>
    )
}