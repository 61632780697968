import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MultilingualBtn from '../../local/MultilingualBtn';
import navStyles from './navbar.module.scss';

export default function Navbar() {
    const { t, i18n } = useTranslation();

    return (
        <header className={`${navStyles.header} ${i18n.language === 'ar' ? navStyles.header_ar : ''}`}>
            <Link to="/home">
                <img src="../images/logo/ArabTechLogo/logo.png" alt="Arabtech logo" className={navStyles.logo} />
            </Link>
            <Nav t={t} i18n={i18n} />
            <div className={navStyles.nav_right_side}>
                <MultilingualBtn t={t} i18n={i18n} />
            </div>
        </header>
    )
}

function Nav({ t, i18n }) {
    return (
        <nav className={navStyles.main_nav}>
            <ul className={`${navStyles.main_nav_list} ${i18n.language === 'ar' ? navStyles.main_nav_list_ar : ''}`}>
                <li>
                    <Link to="/home" className={navStyles.main_nav_link}>
                        {t('home')}
                    </Link>
                </li>
                <li>
                    <Link to="/about" className={navStyles.main_nav_link}>
                        {t('about')}
                    </Link>
                </li>
                <li>
                    <Link to="/projects" className={navStyles.main_nav_link}>
                        {t('projects')}
                    </Link>
                </li>
                <li>
                    <Link to="/documents" className={navStyles.main_nav_link}>
                        {t('documents')}
                    </Link>
                </li>
                <li>
                    <Link to="/contact" className={navStyles.main_nav_link}>
                        {t('contact')}
                    </Link>
                </li>
            </ul>
        </nav>
    )
}
