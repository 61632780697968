import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import docStyles from './documents.module.scss'
import Container from '../container/container'
import { ExternalLink } from "lucide-react";



const GovermentDocs = [
  {
    docImg: 'CR.png',
    docAlt: 'Commercial Registeration',
    docURL: 'CommercialRegisteration.pdf',
  },
  {
    docImg: 'GOSICertificate.png',
    docAlt: 'GOSI Certificate',
    docURL: 'GOSICertificate.pdf',
  },
  {
    docImg: 'ContractorsCertificate.png',
    docAlt: 'Contractors Classification',
    docURL: 'ContractorsClassification.pdf',
  },
  {
    docImg: 'ZakatCertificate.png',
    docAlt: 'Zakat Certificate',
    docURL: 'ZakatCertificate.pdf',
  },
  {
    docImg: 'VAT-Certificate.png',
    docAlt: 'VAT Registration Certificate',
    docURL: 'VATRegistration.pdf',
  },
  {
    docImg: 'SaudizationCertificate.png',
    docAlt: 'Saudization Certificate',
    docURL: 'SaudizationCertificate.pdf',
  },
  {
    docImg: 'FinancialStatements2022.png',
    docAlt: 'Financial Statements 2022',
    docURL: 'FinancialStatements2022.pdf',
  },
  {
    docImg: 'FinancialStatements2023.png',
    docAlt: 'Financial Statements 2023',
    docURL: 'FinancialStatements2023.pdf',
  },

]

const QualificationDocs = [
  {
    docImg: 'NationalGrid.png',
    docAlt: 'Qualification Certificate of National Grid',
    docURL: 'NationalGrid.pdf',
  },
  // {
  //   docImg: 'VAT-Certificate.png',
  //   docAlt: 'VAT Registration Certificate',
  //   docURL: 'VATRegistration.pdf',
  // },

]


const GovermentDocs_ar = [
  {
    docImg: 'CR.png',
    docAlt: 'السجل التجاري',
    docURL: 'CommercialRegisteration.pdf',
  },
  {
    docImg: 'GOSICertificate.png',
    docAlt: 'شهادة التأمينات الاجتماعية',
    docURL: 'GOSICertificate.pdf',
  },
  {
    docImg: 'ContractorsCertificate.png',
    docAlt: 'التصنيف المهني',
    docURL: 'ContractorsClassification.pdf',
  },
  {
    docImg: 'ZakatCertificate.png',
    docAlt: 'شهادة الزكاة والدخل',
    docURL: 'ZakatCertificate.pdf',
  },
  {
    docImg: 'VAT-Certificate.png',
    docAlt: 'شهادة تسجيل ضريبة القيمة المضافة',
    docURL: 'VATRegistration.pdf',
  },
  {
    docImg: 'SaudizationCertificate.png',
    docAlt: 'شهادة التوطين',
    docURL: 'SaudizationCertificate.pdf',
  },
  {
    docImg: 'FinancialStatements2022.png',
    docAlt: 'القوائم المالية 2022',
    docURL: 'FinancialStatements2022.pdf',
  },
  {
    docImg: 'FinancialStatements2023.png',
    docAlt: 'القوائم المالية 2023',
    docURL: 'FinancialStatements2023.pdf',
  },

]

const QualificationDocs_ar = [
  {
    docImg: 'NationalGrid.png',
    docAlt: 'شهادة تأهيل الشبكة الوطنية',
    docURL: 'NationalGrid.pdf',
  },
  // {
  //   docImg: 'VAT-Certificate.png',
  //   docAlt: 'VAT Registration Certificate',
  //   docURL: 'VATRegistration.pdf',
  // },

]



export default function Documents() {
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState("tab1");

  return (
    <Container>

      <div className={`${docStyles.documents} ${i18n.language === 'ar' && docStyles.documents_ar}`}>
        <h1 className='title'>{t('documentsTitle')}</h1>
        {/* Tab nav */}
        <ul className={docStyles.nav}>
          <TabNavItem id="tab1" activeTab={activeTab} setActiveTab={setActiveTab} >{t('GovernmentDocTab')}</TabNavItem>
          <TabNavItem id="2" activeTab={activeTab} setActiveTab={setActiveTab} >{t('QualificationDocTab')}</TabNavItem>
        </ul>

        <div className={docStyles.content}>
          <TabContent id="tab1" activeTab={activeTab}>
            {i18n.language === 'en' ?
              <CoversContainer docs={GovermentDocs} extension='GovermentDocs' /> 
            :
            <CoversContainer docs={GovermentDocs_ar} extension='GovermentDocs' />}
          </TabContent>
          <TabContent id="2" activeTab={activeTab}>
            {i18n.language === 'en' ?
              <CoversContainer docs={QualificationDocs} extension='QualificationDocs' />
            :
            <CoversContainer docs={QualificationDocs_ar} extension='QualificationDocs' />}
          </TabContent>
        </div>

      </div>
    </Container>
  )
}


function TabNavItem({ id, children, activeTab, setActiveTab }) {

  const handleClick = () => {
    setActiveTab(id);
  };

  return (
    <li onClick={handleClick} className={activeTab === id ? `${docStyles.active}` : ""}>
      {children}
    </li>
  );
}

function TabContent({ id, activeTab, children }) {
  return (
    activeTab === id ? <div className={docStyles.TabContent}>
      {children}
    </div>
      : null
  )
}

function CoversContainer({ docs, extension }) {
  return (
    <div className={docStyles.CoversContainer}>

      {docs.map(doc => (
        <div className={docStyles.doc}>
          <img src={`../images/docs/${extension}/${doc.docImg}`} alt={doc.docAlt} />
          <a href={`../docs/${extension}/${doc.docURL}`} className={docStyles.docInfo} target="_blank">
            <h4>{doc.docAlt}</h4>
            <p href={`../docs/${extension}/${doc.docURL}`}><ExternalLink size={16} strokeWidth={2.5} color="#fff" /></p>
          </a>
        </div>
      ))}
    </div>
  )
}