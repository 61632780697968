import React from 'react'
import Container from '../../container/container'
import { useTranslation } from 'react-i18next';
import teamStyles from './team.module.scss'
import { Phone, Mail, Linkedin } from "lucide-react";

const teamMembers = [
  {
    image: 'engKhalid.jpeg',
    name: 'Khalid AlGhamdi',
    position: 'President',
    phone: +966542787001,
    email: 'khalid.al-ghamdi@arabtech-est.com',
    linkedin: '',
  },
  {
    image: 'engShawky.jpeg',
    name: 'Ahmed Shawky',
    position: 'Executive Director',
    phone: +966565840010,
    email: 'ash@arabtech-est.com',
    linkedin: 'ahmed-shawky-b55912104/',
  },
  {
    image: 'mrAhmed.png',
    name: 'Ahmed AbdElSalam',
    position: 'Accounting Manager',
    phone: '',
    email: 'accounts@arabtech-est.com',
    linkedin: 'ahmed-abdelsalam/',
  },
  {
    image: 'engHamid.jpeg',
    name: 'Hamid Gulzar',
    position: 'Technical Manager',
    phone: +966593175330,
    email: 'hamid.ali@arabtech-est.com',
    linkedin: 'hamid-gulzar-106b7050/',
  },
  {
    image: 'engMustafa.jpg',
    name: 'Mustafa Hussein',
    position: 'Field Services Manager',
    phone: +966500093378,
    email: 'mustafa.hussien@arabtech-est.com',
    linkedin: 'mostafa-hussen-88392949/',
  },
  {
    image: 'mrRonald.jpg',
    name: 'Ronald Nicasio',
    position: 'Administration Manager',
    phone: +966565492704,
    email: 'admin@arabtech-est.com',
    linkedin: 'ronaldo-nicasio-0386a029a/',
  },
  {
    image: 'engZahid.jpg',
    name: 'Zahid Gulzar',
    position: 'Tender Section Head',
    phone: +966542768957,
    email: 'zahid.gulzar@arabtech-est.com',
    linkedin: 'zahid-gulzar-86258b307/',
  },
  {
    image: 'engRami.jpeg',
    name: 'Rami AlGhamdi',
    position: 'Public Relations Coordinator',
    phone: +966555112406,
    email: 'rami.alghamdi@arabtech-est.com',
    linkedin: 'rami-alghamdi-401842182/',
  },
  // {
  //   image: 'user6.jpeg',
  //   name: 'Maha ALGhamdi',
  //   position: 'Web developer',
  //   phone: +966545820666,
  //   email: 'maha.alghamdi@arabtech-est.com',
  //   linkedin: 'maha-mg/',
  // },
  // {
  //   image: 'user4.png',
  //   name: 'vacant position',
  //   position: 'vacant position',
  //   phone: '',
  //   email: '',
  //   linkedin: '',
  // },
]

const teamMembers_ar = [
  {
    image: 'engKhalid.jpeg',
    name: 'خالد الغامدي',
    position: 'المالك',
    phone: +966542787001,
    email: 'khalid.al-ghamdi@arabtech-est.com',
    linkedin: '',
  },
  {
    image: 'engShawky.jpeg',
    name: 'أحمد شوقي',
    position: 'المدير التنفيذي',
    phone: +966565840010,
    email: 'ash@arabtech-est.com',
    linkedin: 'ahmed-shawky-b55912104/',
  },
  {
    image: 'mrAhmed.png',
    name: 'أحمد عبدالسلام',
    position: 'مدير المحاسبة',
    phone: '',
    email: 'accounts@arabtech-est.com',
    linkedin: 'ahmed-abdelsalam/',
  },
  {
    image: 'engHamid.jpeg',
    name: 'حميد جولزار',
    position: 'المدير الفني',
    phone: +966593175330,
    email: 'hamid.ali@arabtech-est.com',
    linkedin: 'hamid-gulzar-106b7050/',
  },
  {
    image: 'engMustafa.jpg',
    name: 'مصطفى حسين',
    position: 'مدير الخدمات الميدانية',
    phone: +966500093378,
    email: 'mustafa.hussien@arabtech-est.com',
    linkedin: 'mostafa-hussen-88392949/',
  },
  {
    image: 'mrRonald.jpg',
    name: 'رونالد نيكاسيو',
    position: 'مدير الإدارة',
    phone: +966565492704,
    email: 'admin@arabtech-est.com',
    linkedin: 'ronaldo-nicasio-0386a029a/',
  },
  {
    image: 'engZahid.jpg',
    name: 'زاهد جولزار',
    position: 'رئيس قسم المناقصات',
    phone: +966542768957,
    email: 'zahid.gulzar@arabtech-est.com',
    linkedin: 'zahid-gulzar-86258b307/',
  },
  {
    image: 'engRami.jpeg',
    name: 'رامي الغامدي',
    position: 'منسق العلاقات العامة',
    phone: +966555112406,
    email: 'rami.alghamdi@arabtech-est.com',
    linkedin: 'rami-alghamdi-401842182/',
  }
]

export default function Team() {
  const { t, i18n } = useTranslation();

  return (
    <Container>
      <h1 className="title">{t('teamTitle')}</h1>

      {i18n.language === 'en' ?
        <div className={`${teamStyles.teamContainer} ${i18n.language === 'ar' && teamStyles.teamContainer_ar}`}>

        {teamMembers.map(t =>
          <div className={teamStyles.memberCard}>
            <Member image={t.image} name={t.name} job={t.position} phone={t.phone} email={t.email} linkedin={t.linkedin} />
          </div>
        )}

      </div>
      :
        <div className={`${teamStyles.teamContainer} ${i18n.language === 'ar' && teamStyles.teamContainer_ar}`}>

          {teamMembers_ar.map(t =>
            <div className={teamStyles.memberCard}>
              <Member image={t.image} name={t.name} job={t.position} phone={t.phone} email={t.email} linkedin={t.linkedin} />
            </div>
          )}

        </div>
      }
    </Container>
  )
}


function Member({ image, name, job, phone, email, linkedin }) {
  return (
    <>
      <div className={teamStyles.memberPhoto}>
        <img src={`../images/team/${image}`} alt={name} />
      </div>
      <div className={teamStyles.info}>
        <h2>{name}</h2>
        <h3>{job}</h3>
        {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
      </div>
      <div className={teamStyles.soial}>
        <a href={`tel:${phone}`}><Phone size={16} /></a>
        <a href={`mailto:${email}`}><Mail size={16} /></a>
        {linkedin && (<a href={`https://www.linkedin.com/in/${linkedin}`} target='_blank'><Linkedin size={16} /></a>)}
      </div>
    </>
  )
}