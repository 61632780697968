import React from 'react'
import { useTranslation } from 'react-i18next';
import Sidebar from '../sidebar/sidebar'
import styles from './container.module.scss'

function Container({ children }) {
    const { t, i18n } = useTranslation();

    return (
        
        <div className={`${styles.container} ${i18n.language === 'ar' ? styles.container_ar : ''}`}>

            <aside className={styles.sidebar}>
                <div>
                    <Sidebar />
                </div>
            </aside>

            <div className={styles.mainContainer}>
                {children}
            </div>
        </div>
    )
}

export default Container