import React from 'react'
import Container from '../../../container/container'
import { useTranslation } from 'react-i18next';
import projectStyles from './project.module.scss'
import { MapPin, CalendarCheck } from "lucide-react";

const projectList = [
  {
    title: 'Construction of Sulaihi 33/13.8 KV Substation',
    client: 'KADI / Giza Arabia',
    location: 'Abha',
    description: 'T&C for 13.8 and 33 KV SWGR, TFRs, Protection Panels',
    date: '2024'
  },
  {
    title: 'PP8 GIS Overhauling ',
    client: 'ABB',
    location: 'Riyadh',
    description: 'Retrofitting work with Testing and Commissioning',
    date: '2024'
  },
  {
    title: 'Supply of Two (132kV) Mobile HGIS ',
    client: 'ABB',
    location: 'Jubail',
    description: 'T&C of GIS and Protection Relays (T&C for Complete Mobile Substation)',
    date: '2024'
  },
  {
    title: 'Supply of Two (132kV) Mobile HGIS ',
    client: 'ABB',
    location: 'Tabuk',
    description: 'T&C of GIS and Protection Relays (T&C for Complete Mobile Substation)',
    date: '2024'
  },
  {
    title: 'Construction of Khamis Mushayt Housing 380/132/13.8 KV',
    client: 'Al Toukhi',
    location: 'Khamis Mushayt',
    description: 'DFRA Test for Power Transformer 3Nos. 502MVA & 3Nos. 67MVA Transformers',
    date: '2024'
  },
  {
    title: '6 substations ',
    client: 'ABB',
    location: 'Riyadh',
    description: 'Replacing HV\\CB Control Plug and LCC Panel Modification',
    date: '2024'
  },
  {
    title: 'Al Hawiyah Substation',
    client: 'ABB',
    location: 'Al Taif',
    description: 'Replacement of SIEMENS LCC Panels, CBs, CTs and VTs with T&C ',
    date: '2024'
  },
  {
    title: 'Installation of underground cable to 33KV to feed Metro SS9053 & SS9051 ',
    client: 'Bin Omairah',
    location: 'Riyadh',
    description: 'Testing of MV Cables (VLF Hipot - TD - PD)',
    date: '2024'
  },
  {
    title: 'Metro Substations Project',
    client: 'Saudi Edrak',
    location: 'Riyadh',
    description: 'Testing of MV Cables (VLF Hipot - TD - PD)',
    date: '2024'
  },
  {
    title: 'Metro Substations Project',
    client: 'Al Ojaimi',
    location: 'Riyadh',
    description: 'Testing of MV Cables (VLF Hipot - TD - PD)',
    date: '2024'
  },
  {
    title: 'عقد فحص وصيانة محسنات الشبكة الهوائية بالقطاع الاوسط',
    client: 'Saudi Insustrial Inspection Co.Ltd.',
    location: 'Hail - Al Kharj - Riyadh Area',
    description: 'Inspection of Voltage Regulator, Capacitors, Sectionlizer and Auto Recloser',
    date: '2024'
  },
  {
    title: 'Al-Sodah S/S - Thar S/S - Yadamah S/S - Asir Hospital S/S - Rahwa S/S',
    client: 'Haif',
    location: 'Asir and Najran',
    description: 'Participating in T&C of 5 Substations Bid in Southern Area with SIEMENS',
    date: '2024'
  },
  {
    title: ' Khalidia & Andalus S\\S ',
    client: '',
    location: 'Al Hassa Area',
    description: 'Testing of Surge Arrestor 115KV',
    date: '2024'
  },
  {
    title: 'PDT SS 8223 Riyadh AL Mather',
    client: 'SSEM',
    location: 'Riyadh',
    description: 'HV Test for 132KV GIS and Power Cables',
    date: '2024'
  },
  {
    title: 'PDT SS T802 JUBAIL RIC-1',
    client: 'SSEM',
    location: 'Jubail',
    description: 'HV Test for 115KV GIS and Power Cables',
    date: '2024'
  },
  {
    title: 'PDT SS 8725 Al Kharj',
    client: 'SSEM',
    location: 'Al Kharj',
    description: 'HV Test for 132KV GIS and Power Cables',
    date: '2024'
  },

]

export default function Projects() {
  const { t, i18n } = useTranslation();

  return (
    <Container>
      <h1 className='title'>{t('projects')}</h1>

      <section id={projectStyles.projectTimeline} className={`${projectStyles.projectContainer} ${i18n.language === 'ar' && projectStyles.projectContainer_ar }`}>

        {/* {projectList.reverse().map(project => ())} */}

        {projectList.map(project => (
          <div className={projectStyles.projectBlock}>
            <div className={projectStyles.timelineCircle}></div>
            <div className={projectStyles.projectContent}>
              <h2>{project.title}</h2>
              <div className={projectStyles.projectContentInfo}>
                <span className={projectStyles.projectContentInfoLocation}>
                  {project.client} <span ><MapPin size={14} /> {project.location}</span>
                </span>
              </div>
              <p>{project.description}</p>
              <span className={projectStyles.projectDate}>
                <CalendarCheck size={14} />&nbsp; {project.date}
              </span>
            </div>
          </div>
        ))}

      </section>
    </Container>
  )
}

